document.addEventListener("DOMContentLoaded", function () {
    var reg = document.querySelector(".reg");

    if (reg) {
        var errorText = document.querySelector(".error-text");

        if (errorText) {
            var viewportOffset = errorText.getBoundingClientRect();
            setTimeout(function () {
                window.scroll({
                    top: viewportOffset.top + window.scrollY,
                    behavior: "smooth",
                });
            }, 500);
        }

        var inputs = document.querySelectorAll(".reg .error");

        if (inputs.length > 0) {
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].addEventListener("click", function () {
                    if (this.classList.contains("error")) {
                        this.classList.remove("error");
                    }
                });
            }
        }

        var address = document.getElementById("address");

        if (address) {
            var lottozo = document.getElementById("lottozo");
            var regio = document.getElementById("regio");
            var fovaros = document.getElementById("fovaros");

            address.addEventListener("change", function () {
                if (this.value == 1) {
                    regio.classList.add("d-none");
                    fovaros.classList.add("d-none");
                    lottozo.classList.remove("d-none");
                } else if (this.value == 2) {
                    lottozo.classList.add("d-none");
                    fovaros.classList.add("d-none");
                    regio.classList.remove("d-none");
                } else if (this.value == 3) {
                    lottozo.classList.add("d-none");
                    regio.classList.add("d-none");
                    fovaros.classList.remove("d-none");
                } else {
                    lottozo.classList.add("d-none");
                    regio.classList.add("d-none");
                    fovaros.classList.add("d-none");
                }
            });
        }

        var package_lottery_id = document.getElementById("package_lottery_id");
        if (package_lottery_id) {
            var terminal = document.getElementById("terminal");

            package_lottery_id.addEventListener("change", function () {
                if (this.value !== "") {
                    terminal.classList.remove("d-none");
                } else {
                    terminal.classList.add("d-none");
                }
            });
        }
    }

    var kidsContent = document.querySelectorAll("#kidsContent > .col");

    if (kidsContent.length > 0) {
        var kidsNumber = document.getElementById("kids");

        if (kidsNumber) {
            kidsNumber.addEventListener("change", function () {
                for (var i = 0; i < kidsContent.length; i++) {
                    kidsContent[i].style.display = "none";
                }

                for (var x = 0; x < kidsNumber.value; x++) {
                    kidsContent[x].style.display = "block";
                }
            });
        }
    }

    var otherSelect1 = document.querySelector(
        "#kidsContent .kid1 .kid-data-type",
    );

    if (otherSelect1) {
        otherSelect1.addEventListener("change", function () {
            if (this.value == 1) {
                document.querySelector(
                    "#kidsContent .kid1 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid1 .tax",
                ).style.display = "block";
            } else if (this.value == 2) {
                document.querySelector(
                    "#kidsContent .kid1 .tax",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid1 .other",
                ).style.display = "block";
            } else {
                document.querySelector(
                    "#kidsContent .kid1 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid1 .tax",
                ).style.display = "none";
            }
        });
    }

    var otherSelect2 = document.querySelector(
        "#kidsContent .kid2 .kid-data-type",
    );

    if (otherSelect2) {
        otherSelect2.addEventListener("change", function () {
            if (this.value == 1) {
                document.querySelector(
                    "#kidsContent .kid2 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid2 .tax",
                ).style.display = "block";
            } else if (this.value == 2) {
                document.querySelector(
                    "#kidsContent .kid2 .tax",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid2 .other",
                ).style.display = "block";
            } else {
                document.querySelector(
                    "#kidsContent .kid2 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid2 .tax",
                ).style.display = "none";
            }
        });
    }

    var otherSelect3 = document.querySelector(
        "#kidsContent .kid3 .kid-data-type",
    );

    if (otherSelect3) {
        otherSelect3.addEventListener("change", function () {
            if (this.value == 1) {
                document.querySelector(
                    "#kidsContent .kid3 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid3 .tax",
                ).style.display = "block";
            } else if (this.value == 2) {
                document.querySelector(
                    "#kidsContent .kid3 .tax",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid3 .other",
                ).style.display = "block";
            } else {
                document.querySelector(
                    "#kidsContent .kid3 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid3 .tax",
                ).style.display = "none";
            }
        });
    }

    var otherSelect4 = document.querySelector(
        "#kidsContent .kid4 .kid-data-type",
    );

    if (otherSelect4) {
        otherSelect4.addEventListener("change", function () {
            if (this.value == 1) {
                document.querySelector(
                    "#kidsContent .kid4 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid4 .tax",
                ).style.display = "block";
            } else if (this.value == 2) {
                document.querySelector(
                    "#kidsContent .kid4 .tax",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid4 .other",
                ).style.display = "block";
            } else {
                document.querySelector(
                    "#kidsContent .kid4 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid4 .tax",
                ).style.display = "none";
            }
        });
    }

    var otherSelect5 = document.querySelector(
        "#kidsContent .kid5 .kid-data-type",
    );

    if (otherSelect5) {
        otherSelect5.addEventListener("change", function () {
            if (this.value == 1) {
                document.querySelector(
                    "#kidsContent .kid5 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid5 .tax",
                ).style.display = "block";
            } else if (this.value == 2) {
                document.querySelector(
                    "#kidsContent .kid5 .tax",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid5 .other",
                ).style.display = "block";
            } else {
                document.querySelector(
                    "#kidsContent .kid5 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid5 .tax",
                ).style.display = "none";
            }
        });
    }

    var otherSelect6 = document.querySelector(
        "#kidsContent .kid6 .kid-data-type",
    );

    if (otherSelect6) {
        otherSelect6.addEventListener("change", function () {
            if (this.value == 1) {
                document.querySelector(
                    "#kidsContent .kid6 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid6 .tax",
                ).style.display = "block";
            } else if (this.value == 2) {
                document.querySelector(
                    "#kidsContent .kid6 .tax",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid6 .other",
                ).style.display = "block";
            } else {
                document.querySelector(
                    "#kidsContent .kid6 .other",
                ).style.display = "none";
                document.querySelector(
                    "#kidsContent .kid6 .tax",
                ).style.display = "none";
            }
        });
    }

    var modal = document.getElementById("modal");

    if (modal) {
        var close = document.querySelector("#modal .modal-close");

        document.querySelector("html").style.overflow = "hidden";

        if (close) {
            close.addEventListener("click", function () {
                modal.classList.add("hide");
                setTimeout(function () {
                    modal.remove();
                    document.querySelector("html").style.overflow = "auto";
                }, 500);
            });
        }
    }
});
