document.addEventListener("DOMContentLoaded", function () {
    const imgPopup = document.querySelectorAll(".img-popup");

    if (imgPopup) {
        for (let i = 0; i < imgPopup.length; i++) {
            imgPopup[i].addEventListener("click", function (e) {
                e.preventDefault();
                document.querySelector("html").style.overflow = "hidden";

                var imgPopupDiv = document.createElement("div");
                imgPopupDiv.id = "img-popup";
                setTimeout(function () {
                    imgPopupDiv.classList.add("show");
                });

                var imgPopupContentDiv = document.createElement("div");
                imgPopupContentDiv.classList.add("img-popup-content");

                var imgPopupContentInnerDiv = document.createElement("div");
                imgPopupContentInnerDiv.classList.add(
                    "img-popup-content-inner",
                );

                var imgPopupImg = document.createElement("img");
                imgPopupImg.src = this.href;

                var imgPopupCloseDiv = document.createElement("div");
                imgPopupCloseDiv.classList.add("img-popup-close");
                imgPopupCloseDiv.addEventListener("click", function () {
                    imgPopupDiv.classList.remove("show");
                    imgPopupDiv.classList.add("hide");
                    setTimeout(function () {
                        imgPopupDiv.remove();
                        document.querySelector("html").style.overflow = "auto";
                    }, 500);
                });

                var imgPopupCloseImg = document.createElement("img");
                imgPopupCloseImg.src = "/images/icon_close.svg";

                var imgPopupText = document.createElement("p");
                imgPopupText.innerText = this.nextElementSibling.innerText;

                imgPopupCloseDiv.append(imgPopupCloseImg);
                imgPopupDiv.append(imgPopupCloseDiv);

                imgPopupContentInnerDiv.append(imgPopupImg);
                imgPopupContentInnerDiv.append(imgPopupText);

                imgPopupContentDiv.append(imgPopupContentInnerDiv);

                imgPopupDiv.append(imgPopupContentDiv);

                document.body.append(imgPopupDiv);
            });
        }
    }
});
